<template>
  <div class="calc">
    <!-- Intro Banner
    ================================================== -->
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="intro-banner intro-banner-sm">
      <div class="background-image-container" v-bind:style="styleBanner"></div>
      <div class="container">

        <!-- Intro Headline -->
        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h3>
                <strong>GiveUp to GiveBack</strong>
              </h3>
              <h4>What would you be willing to Give Up for the Cause</h4>
            </div>
          </div>
        </div>
        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-10">
            <div class="intro-banner-search-form margin-top-95">


              <!-- Search Field -->
              <div class="intro-search-field">
                <label class="field-title ripple-effect"> Item to Give Up </label>
                <select class="default"
                        v-select-picker
                        v-model="itemAmount"
                >
                  <option data-subtext="$1" :value="1"><span class="picker-icon">🎧</span> A Purchased Song</option>
                  <option data-subtext="$3" :value="3"><span class="picker-icon">☕️</span> A Cup of Coffee</option>
                  <option data-subtext="$5" :value="5"><span class="picker-icon">🍷</span> A Glass of Wine</option>
                  <option data-subtext="$10" :value="10"><span class="picker-icon">🎬</span> A Movie Ticket</option>
                  <option data-subtext="$20" :value="20"><span class="picker-icon">🛍️</span> A Shopping Trip</option>
                  <option data-subtext="$30" :value="30"><span class="picker-icon">💈</span> A Haircut</option>
                  <option data-subtext="$40" :value="40"><span class="picker-icon">⛽</span> A Tank of Gas</option>
                </select>
              </div>

              <!-- Search Field -->
              <div class="intro-search-field no-label">
                <label class="field-title ripple-effect hide-under-992px"> How often? </label>
                <select class="default"
                        data-selected-text-format="count"
                        data-size="7"
                        v-select-picker
                        v-model="period"
                >
                  <option value="one-time">One-Time</option>
                  <option selected value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              <!-- Button -->
              <div class="intro-search-button">
                <button class="button ripple-effect"
                        v-on:click.prevent="goDonate">
                  Donate <sup>$</sup>{{donateAmount | formatMoney}}{{period !== 'one-time' ? '/mo' : ''}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Stats -->
        <div class="row">
          <div class="col-md-12">
            <ul class="intro-stats giveup-stats margin-top-35">
              <li v-if="period !== 'one-time'">
                <strong class="counter"><sup>$</sup>{{donateAmount / 30 | formatMoney}}</strong>
                <span>Daily Cost</span>
              </li>
              <li>
                <strong class="counter"><sup>$</sup>{{donateAmount | formatMoney}}</strong>
                <span>{{period === 'one-time' ? 'One-Time' : 'Monthly'}} Cost</span>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'DonationCalculator',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo', 'version'],
    methods: {
      goDonate() {
        let queryParams = {};

        queryParams['amount'] = this.donateAmount;
        queryParams['smart_ask'] = false;
        queryParams['type'] = this.period === 'one-time' ? 'one-time' : 'monthly';

        this.$router.push({
          name: 'DonationProceed',
          query: queryParams
        });
      }
    },
    computed: {
      styleBanner() {
        let res = {};
        if (this.charityInfo && this.charityInfo.cover_photo) {
          res['background-image'] = `url('${this.charityInfo.cover_photo}')`;
        }
        return res;
      },
      donateAmount() {
        if (this.period === 'weekly') {
          return this.itemAmount * 4;
        }
        return this.itemAmount;
      }
    },
    data() {
      return {
        itemAmount: 1,
        period: 'weekly',
      };
    },
  }
</script>