import config from '../../config.json';

export default {
  methods: {
    deleteElement(selector) {
      if (document.querySelector(selector)) {
        const widgetWrap = document.querySelector(selector);
        widgetWrap.parentNode.removeChild(widgetWrap);
      }
    },
    attachJsExampleV2(charity_id) {
      this.deleteElement('.h-widget-wrap');
      this.deleteElement('.test-script-wrap');

      const widgetUrl = `${config.harness.scheme}://widget.${config.harness.url}/harness-widget-v2.js`;

      (function(url, callback) {
        // Adding the script tag to the head as suggested before
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        // Then bind the event to the callback function.
        // There are several events for cross browser compatibility.
        script.onreadystatechange = callback;
        script.onload = callback;

        const scriptWrap = document.createElement('div');
        scriptWrap.classList.add('test-script-wrap');
        scriptWrap.appendChild(script);

        document.body.appendChild(scriptWrap);

      })(widgetUrl, function() {
        window.HarnessWidget.init({charity_id});
      });
    },
  },
};