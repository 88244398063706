<template>
  <div v-if="isDesktop2"
       class="container-fluid"
       :class="{'container-fluid-no-header': $root.$children[0].header_disable}"
  >

    <div class="row">
      <div class="col-sm-6">
        <div :class="classContainerLeft">
          <slot v-bind:isDesktop2="isDesktop2"></slot>
        </div>
      </div>
      <div class="col-sm-6 container-right" v-bind:style="styleBg">
        <div v-if="previewCampaign && previewCampaign.id"
             class="blog-post blog-post-float"
             :class="{'blog-post-float-full': campaignReadMore}"
        >
          <div class="blog-post-content">
            <h4 class="fw-600">{{previewCampaign.name}}</h4>

            <p v-if="campaignReadMore"
               style="white-space: pre-wrap;"
               v-html="`${previewCampaign.description}`"></p>
            <p v-else class="two-lines">{{stripHtml(previewCampaign.description)}}</p>

            <a v-if="wordsCount > wordsLimit && !campaignReadMore"
               v-on:click.prevent="campaignReadMore = true"
               href="#"
            ><small>Read More</small></a>
            <a v-else-if="wordsCount > wordsLimit"
               v-on:click.prevent="campaignReadMore = false"
               href="#"
            ><small>Read Less</small></a>

        </div>
          <div class="entry-icon"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="container margin-top-30">
    <div class="row">
      <div class="col-xl-5 offset-xl-3">
        <slot></slot>
      </div>
    </div>
    <magnific-popup-modal
        :id="'small-dialog-1'"
        :show="false"
        :config="$root.popupConfig"
        ref="campaignInfoModal"
        :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">
        <ul class="popup-tabs-nav">
          <li><a href="#">Campaign</a></li>
        </ul>
        <div v-if="previewCampaign"
             class="popup-tabs-container">
          <div class="popup-tab-content padding-left-15 padding-right-15 padding-bottom-20 padding-top-20" >
            <h3 class="margin-bottom-5">{{previewCampaign.name}}</h3>

            <p style="white-space: pre-wrap;"
               v-html="`${previewCampaign.description}`"></p>
          </div>
        </div>
      </div>
    </magnific-popup-modal>

  </div>
</template>

<script>

  import {stripHtml} from "../../utils/utils";
  import MagnificPopupModal from '../Parts/MagnificPopupModal'

  export default {
    name: 'InnerLayout',
    components:{
      MagnificPopupModal
    },
    props: {
      previewCampaign: null,
      charityInfo: Object,
      coverPhoto: String,
      classContainerLeft: {
        type: Array,
        'default': () => ['container-left']
      },
      class1: {
        type: Array,
        'default': () => ['container', 'margin-top-30']
      },
      class2: {
        type: Array,
        'default': () => ['row']
      },
      class3: {
        type: Array,
        'default': () => ['col-xl-5', 'offset-xl-3']
      },
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
      if (localStorage.desktop) {
        this.desktop = !!localStorage.desktop;
      }
    },
    methods: {
      showCampaignInfo(){
        this.$refs.campaignInfoModal.open();
      },
      stripHtml(t){
        return stripHtml(t);
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    computed: {
      isDesktop2() {
        return !this.desktop && this.window.width > 950;
      },
      styleBg() {
        if(this.coverPhoto){
          return {
            'background-image': `url('${this.coverPhoto}')`
          };
        }
        if (this.charityInfo) {
          return {
            'background-image': `url('${this.charityInfo.cover_photo}')`
          };
        }
        return {};
      },
      wordsCount(){
        if(!this.previewCampaign){
          return 0;
        }
        return stripHtml(this.previewCampaign.description).trim().split(/\s+/).length
      },
    },
    watch: {
      previewCampaign(){
        this.campaignReadMore = this.wordsCount <= this.wordsLimit;
      }
    },
    data: function () {
      return {
//        desktop2: false,
        desktop: false,//desktop2 - default
        window: {
          width: 0,
          height: 0
        },
        wordsLimit: 60,
        campaignReadMore: false
      }
    },
  }
</script>
