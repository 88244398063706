<template>
  <inner-layout :charity-info="charityInfo"
                :classContainerLeft="['container-left', 'container-left-wide']"
  >

    <div class="section-headline  margin-bottom-20">
      <h1><strong class="fw-600">Make a Donation</strong></h1>
      <p>Please choose one of the following:</p>
    </div>

    <router-link v-for="(impact, index) in impacts"
                 :key="index"
                 :to="{name: 'Donate', query: { amount: impact.amount/100 }}"
                 class="blog-post">
      <!-- Blog Post Thumbnail -->
      <div class="blog-post-thumbnail">
        <div class="blog-post-thumbnail-inner">
          <img :src="impact.image?impact.image:charityInfo.cover_photo" :alt="impact.name">
        </div>
      </div>
      <!-- Impact Amount Post Content -->
      <div class="blog-post-content">
        <span class="blog-post-date bg-primary" style="color: #fff;font-weight:600;">${{impact.amount/100 | formatMoney}} Donation</span>
        <h3 class="fw-600">{{impact.name}}</h3>
        <p>{{impact.description}}</p>
      </div>
      <!-- Icon -->
      <div class="entry-icon"></div>
    </router-link>

  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import {getGetDonationImpactAmounts} from '../../utils/api'

  export default {
    name: 'ImpactAmounts',
    props: ['isLogin', 'charityInfo', 'donorDetail'],
    components: {
      InnerLayout,
    },
    mounted() {
      this.updateImpacts();
    },
    methods: {
      updateImpacts(){
        if (this.charityInfo) {
          getGetDonationImpactAmounts(this.charityInfo.charity_id).then(impacts => this.impacts = impacts.impacts);
        }
      }
    },
    watch: {
      'charityInfo'() {
        this.updateImpacts();
      },
    },
    data() {
      return {
        impacts: [],
      };
    },
  }
</script>
