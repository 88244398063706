<template>
  <div>

    <!-- Intro Banner
    ================================================== -->
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="intro-banner" :style="styleBanner">
      <div class="container">

        <!-- Intro Headline -->
        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h4>{{charityInfo?charityInfo.charity_name:''}}</h4>
              <h3>
                <strong>Create a Donation Link</strong>
              </h3>
              <!--<span style="color:#fff;">Customize your donation ask and create a link</span>-->
            </div>
          </div>
        </div>

        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-12">
            <div class="intro-banner-search-form margin-top-95">

              <!-- Search Field -->
              <div class="intro-search-field with-autocomplete">
                <label class="field-title ripple-effect"><i
                    class="margin-right-3"><font-awesome-icon :icon="['far', 'clipboard-list']" /></i> Customize your
                  Ask </label>
                <div class="input-with-icon" :class="{'disabled': selectedType === DonationTypes.ROUNDUP}">
                  <input type="text"
                         placeholder="50"
                         :disabled="selectedType === DonationTypes.ROUNDUP"
                         ref="donationAmount"
                         v-model="donationAmount"
                  >
                  <i><font-awesome-icon :icon="['far', 'dollar-sign']" /></i>
                </div>
              </div>

              <!-- Search Field -->
              <div class="intro-search-field">
                <select class="default"
                        v-select-picker
                        v-model="selectedType">
                  <option value="one-time">One-Time</option>
                  <option value="monthly">Monthly</option>
                  <option :value="DonationTypes.ROUNDUP">Round-Ups</option>
                </select>
              </div>

              <!-- Search Field -->
              <div v-if="allCampaigns.length" class="intro-search-field">
                <select v-model="selectedCampaignId"
                        v-select-picker
                        class="select-campaign default"
                        data-selected-text-format="count"
                        data-live-search="true"
                        title="No Campaign"
                >
                  <option v-for="(campaign, index) in orderedCampaigns"
                          :key="index"
                          :value="campaign.id"
                          :data-subtext="goalPercent(campaign)"
                  >{{campaign.name}}
                  </option>
                </select>
              </div>

              <!-- Button -->
              <div class="intro-search-button">
                <button class="button ripple-effect popup-with-zoom-anim"
                        v-on:click.prevent="showLinkModal"
                >Create a Link
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Stats -->
        <div class="row">
          <div class="col-md-12">
            <ul v-if="allCampaigns.length" class="intro-stats margin-top-45 hide-under-992px">
              <li>
                <strong class="counter">{{allCampaigns.length}}</strong>
                <span>Campaigns</span>
              </li>
              <li>
                <strong class="counter">{{statistic.donations}}</strong>
                <span>Donations</span>
              </li>
              <!--li>
                <strong class="counter">{{statistic.donated / 100 | formatMoney}}</strong>
                <span>Total Donations ($)</span>
              </li-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Content
    ================================================== -->

    <!-- Category Boxes -->
    <div class="section margin-top-65 margin-bottom-60">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <div class="section-headline centered margin-bottom-15">
              <h3>Main Menu</h3>
              <p>See which options work best for your organization</p>
            </div>

            <!-- Category Boxes Container -->
            <div class="categories-container">

              <!-- Category Box -->
              <a href="#pages-multiple"
                 v-scroll-to="{el: '#pages-multiple', offset: -82}"
                 class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'desktop']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Full Pages</h3>
                  <p>Link from your website to a full donation page</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="#popups-banner"
                 v-scroll-to="{el: '#popups-banner', offset: -82}"
                 class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'link']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Pop-Up Links</h3>
                  <p>Add #donate as a link to your own website's button</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/settings/website-code" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'toggle-on']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Floating Button</h3>
                  <p>Use our floating button to trigger your donation pop-up</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="#automessages-banner"
                 v-scroll-to="{el: '#automessages-banner', offset: -82}"
                 class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'comment']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Auto Messages</h3>
                  <p>Customize your automatic text messages to donors</p>
                </div>
              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Category Boxes / End -->

    <!-- Photo Section -->
    <div class="photo-section"
         id="pages-multiple"
         :style="`background-image: url('${require('../../assets/website_bg.png')}')`"
    >

      <div class="text-content white-font">
        <div class="container">

          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <h4>FULL PAGE - MULTIPLE OPTIONS</h4>
              <h2>General Donation Page</h2>
              <p style="opacity:1">Your general donation page has all 3 donation options available!<br>
                Choose which donation type you'd like to default to using the links: </p>
              <router-link :to="{name: 'Donate', query: {'selected-method': this.DonationTypes.ONE_TIME}}"
                           target="_blank"
                           class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              > One-Time <i><font-awesome-icon :icon="['far', 'credit-card']" /></i></router-link>
              <router-link :to="{name: 'Donate', query: {'selected-method': this.DonationTypes.MONTHLY}}"
                           target="_blank"
                           class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              > Monthly <i><font-awesome-icon :icon="['far', 'calendar']" /></i></router-link>
              <router-link :to="{name: 'Donate', query: {'selected-method': this.DonationTypes.ROUNDUP}}"
                           target="_blank"
                           class="button button-sliding-icon ripple-effect big margin-top-20"
              > Round-Ups <i><font-awesome-icon :icon="['far', 'usd-circle']" /></i></router-link>
            </div>
          </div>

        </div>
      </div>


    </div>
    <!-- Photo Section / End -->

    <!-- Recent Blog Posts -->
    <div class="section white padding-top-60 padding-bottom-60 full-width-carousel-fix" id="pages-single">

      <div class="container">

        <!-- Section Headline -->
        <div class="section-headline margin-top-0 margin-bottom-45">
          <h3><em>ONLY</em> One Option Pages</h3>
          <p>Below are links for pages that ONLY show one donation type</p>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <slick-carousel class="blog-carousel" :options="blogCarousel">
              <router-link :to="{name: 'Donate', query: {method: DonationTypes.ONE_TIME}}"
                           target="_blank"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img :src="require('../Parts/bg_donationhistory2.png')" alt="">
                  <span class="blog-item-tag">Full Page</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>ONLY SHOW</li>
                    </ul>
                    <h3>One-Time Donation</h3>
                    <p>One-Time Donation to your cause only</p>
                  </div>
                </div>
              </router-link>

              <router-link :to="{name: 'Donate', query: {method: DonationTypes.MONTHLY}}"
                           target="_blank"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img :src="require('../Parts/bg_calendar.png')" alt="">
                  <span class="blog-item-tag">Full Page</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>ONLY SHOW</li>
                    </ul>
                    <h3>Monthly Donations</h3>
                    <p>Make a monthly gift to your organization</p>
                  </div>
                </div>
              </router-link>

              <router-link :to="{name: 'Donate', query: {method: DonationTypes.ROUNDUP}}"
                           target="_blank"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img :src="require('../Parts/bg_register_coins.png')" alt="">
                  <span class="blog-item-tag">Full Page</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>ONLY SHOW</li>
                    </ul>
                    <h3>Round-Ups</h3>
                    <p>Donate your "Round-Up" spare change</p>
                  </div>
                </div>
              </router-link>

              <router-link :to="{name: 'CampaignsList'}"
                           target="_blank"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img :src="require('../../assets/bg_sign.png')" alt="">
                  <span class="blog-item-tag">Full Page</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>ONLY SHOW</li>
                    </ul>
                    <h3>Campaigns</h3>
                    <p>View all of your active campaigns</p>
                  </div>
                </div>
              </router-link>

              <router-link :to="{name: 'DonationCalculator'}"
                           target="_blank"
                           class="blog-compact-item-container">
                <div class="blog-compact-item">
                  <img :src="require('../../assets/bg_present_blue.png')" alt="">
                  <span class="blog-item-tag">NEW</span>
                  <div class="blog-compact-item-content">
                    <ul class="blog-post-tags">
                      <li>Bonus Page</li>
                    </ul>
                    <h3>GiveUp to GiveBack</h3>
                    <p>What would you be willing to give up</p>
                  </div>
                </div>
              </router-link>

            </slick-carousel>

          </div>
        </div>
      </div>
    </div>
    <!-- Recent Blog Posts / End -->

    <!-- Photo Section -->
    <div class="photo-section"
         :style="`background-image: url('${require('../../assets/bubbles_wide.png')}')`"
         id="popups-banner">

      <div class="text-content white-font">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-8 col-sm-12">
              <h4>POP-UPS</h4>
              <h2>Donation Pop-Up Options</h2>
              <p style="opacity:1">Want to keep donors on your website? Use a donation pop-up!<br> Simply add our
                <a href="https://admin.harnessapp.com/settings/website-code"
                   target="_blank"
                   class="fw-600">
                  <mark class="color">Harness Code</mark>
                </a> to your website once and use #links
              </p>
              <a href="#donate"
                 class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              >#donate<i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
              <a href="#monthly"
                 class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              >#monthly<i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
              <a href="#roundup"
                 class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              >#roundup<i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
              <a href="#campaigns"
                 class="button button-sliding-icon ripple-effect big margin-top-20"
              >#campaigns<i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
            </div>
          </div>

        </div>
      </div>


    </div>
    <!-- Photo Section / End -->


    <!-- Icon Boxes -->
    <div class="section padding-top-65 padding-bottom-65">
      <div class="container">
        <div class="row">

          <div class="col-xl-12">
            <!-- Section Headline -->
            <div class="section-headline centered margin-top-0 margin-bottom-5">
              <h3>How to "Harness your Website"</h3>
            </div>
          </div>

          <div class="col-xl-4 col-md-4">
            <!-- Icon Box -->
            <div class="icon-box with-line">
              <!-- Icon -->
              <div class="icon-box-circle">
                <div class="icon-box-circle-inner">
                  <font-awesome-icon :icon="['fal', 'file-alt']"/>
                  <div class="icon-box-check"><font-awesome-icon :icon="['far', 'paint-brush-alt']"/></div>
                </div>
              </div>
              <h3>Copy your Code</h3>
              <p>In the Admin Dashbord, go to <a href="https://admin.harnessapp.com/settings/website-code"
                                                 target="_blank">Settings > Website Code</a> and copy the code displayed
              </p>
            </div>
          </div>

          <div class="col-xl-4 col-md-4">
            <!-- Icon Box -->
            <div class="icon-box with-line">
              <!-- Icon -->
              <div class="icon-box-circle">
                <div class="icon-box-circle-inner">
                  <font-awesome-icon :icon="['fal', 'link']"/>
                  <div class="icon-box-check"><font-awesome-icon :icon="['far', 'plus']"/></div>
                </div>
              </div>
              <h3>Paste into HEAD</h3>
              <p>Paste the code into the HEAD of every page of your website (or use our <a
                  href="http://help.harnessapp.com/en/articles/2869012-integrations-overview" target="_blank">Instructions
                Guide</a>)</p>
            </div>
          </div>

          <div class="col-xl-4 col-md-4">
            <!-- Icon Box -->
            <div class="icon-box">
              <!-- Icon -->
              <div class="icon-box-circle">
                <div class="icon-box-circle-inner">
                  <font-awesome-icon :icon="['fal', 'comments-alt']"/>
                  <div class="icon-box-check"><font-awesome-icon :icon="['far', 'check']"/></div>
                </div>
              </div>
              <h3>Customize your Options</h3>
              <p>On the same <a href="https://admin.harnessapp.com/settings/website-code" target="_blank">Settings >
                Website Code</a> page, you can control how your pop-ups display</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Icon Boxes / End -->


    <!-- Recent Blog Posts -->
    <div class="section padding-top-65 padding-bottom-50">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <!-- Section Headline -->
            <div class="section-headline margin-top-0 margin-bottom-45">
              <h3>Resources & Guides</h3>
              <p>Check out our Harness Knowledge Center & Resources</p>
              <a href="https://blog.harnessapp.com" target="_blank" class="headline-link"
              >View More <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></a>
            </div>

            <div class="row">
              <!-- Blog Post Item -->
              <div class="col-xl-4">
                <a href="https://admin.harnessapp.com/settings/website-code"
                   target="_blank"
                   class="blog-compact-item-container">
                  <div class="blog-compact-item">
                    <img :src="require('../../assets/bg_landingpage.png')" alt="">
                    <span class="blog-item-tag">Tips</span>
                    <div class="blog-compact-item-content">
                      <ul class="blog-post-tags">
                        <li>Harness</li>
                      </ul>
                      <h3>Harness your Website</h3>
                      <p>Turn your website into a fundraising tool</p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- Blog post Item / End -->

              <!-- Blog Post Item -->
              <div class="col-xl-4">
                <a href="http://help.harnessapp.com/en/"
                   target="_blank"
                   class="blog-compact-item-container">
                  <div class="blog-compact-item">
                    <img :src="require('../../assets/bg_idea1.png')" alt="">
                    <span class="blog-item-tag">Support</span>
                    <div class="blog-compact-item-content">
                      <ul class="blog-post-tags">
                        <li>Harness</li>
                      </ul>
                      <h3>Help Center</h3>
                      <p>Tips & Best Practices to help you succeed</p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- Blog post Item / End -->

              <!-- Blog Post Item -->
              <div class="col-xl-4">
                <a href="https://blog.harnessapp.com/integrations"
                   target="_blank"
                   class="blog-compact-item-container">
                  <div class="blog-compact-item">
                    <img :src="require('../../assets/bg_socket.png')" alt="">
                    <span class="blog-item-tag">Resources</span>
                    <div class="blog-compact-item-content">
                      <ul class="blog-post-tags">
                        <li>Harness</li>
                      </ul>
                      <h3>Integrations</h3>
                      <p>Check out our growing list of integrations</p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- Blog post Item / End -->
            </div>


          </div>
        </div>
      </div>
    </div>
    <!-- Recent Blog Posts / End -->


    <!-- Donation Campaigns Section -->
    <div class="photo-section"
         :style="`background-image: url('${require('../../assets/fundraisers_bg2.png')}')`"
    >
      <div class="text-content white-font">
        <div class="container">

          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <h2>Donation Campaigns</h2>
              <p style="opacity:1">Create donation campaigns to provide donors with a specific goal. Campaigns can have
                a donation goal & end date to build urgency! </p>
              <router-link :to="{name: 'CampaignsList'}"
                           target="_blank"
                           class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              >Campaigns Page <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></router-link>
              <a href="https://admin.harnessapp.com/add-campaign"
                 target="_blank"
                 class="button button-sliding-icon ripple-effect big margin-top-20"
              >Create a Campaign <i><font-awesome-icon :icon="['far', 'plus-circle']" /></i></a>
            </div>
          </div>

        </div>
      </div>


    </div>
    <!-- Donation Campaigns Section / End -->


    <!-- Category Boxes -->
    <div class="section margin-top-65 margin-bottom-50">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <div class="section-headline centered margin-bottom-15">
              <h3>Campaign Pages</h3>
              <p>View the Campaign Pages available for your organization</p>
            </div>

            <!-- Category Boxes Container -->
            <div class="categories-container">

              <!-- Category Box -->
              <router-link :to="{name: 'CampaignsList'}"
                           target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'desktop']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Landing Page</h3>
                  <p>View your organization's landing page for showcasing campaigns</p>
                </div>
              </router-link>

              <!-- Category Box -->
              <router-link :to="{name: 'CampaignsGrid'}"
                           target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'search']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Search Page</h3>
                  <p>View the Campaigns page where donors can find your campaigns</p>
                </div>
              </router-link>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/campaigns" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'clipboard-list']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Campaigns Dashboard</h3>
                  <p>View your list of campaigns you've set up in your dashboard</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/add-campaign" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'plus']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Create a Campaign</h3>
                  <p>Create fundraising campaigns & goals for donors to donate to</p>
                </div>
              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Category Boxes / End -->

    <!-- Campaigns List-->
    <div class="section gray padding-top-65 padding-bottom-70 full-width-carousel-fix">
      <div class="container">
        <div class="row">

          <div class="col-xl-12">
            <!-- Section Headline -->
            <div class="section-headline margin-top-0 margin-bottom-25">
              <h3>Campaigns</h3>
              <router-link :to="{name: 'CampaignsGrid'}"
                           target="_blank"
                           class="headline-link"
              >View All <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" />
              </router-link>
            </div>
          </div>

          <div class="col-xl-12">
            <campaigns-slider v-if="allCampaigns.length"
                              :sortedCampaigns="allCampaigns"
                              :charityInfo="charityInfo"
            ></campaigns-slider>
          </div>

        </div>
      </div>
    </div>
    <!-- Campaigns List / End-->


    <!-- Auto Messages Section -->
    <div class="photo-section"
         :style="`background-image: url('${require('../../assets/bg_phone_gray.png')}')`"
         id="automessages-banner">

      <div class="text-content white-font">
        <div class="container">

          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <h2>Auto Messages</h2>
              <p style="opacity:1">Customize your automatic text messages to donors! Save tons of time by setting custom
                messages for different types of donations! </p>
              <a href="https://admin.harnessapp.com/auto-messages"
                 target="_blank"
                 class="button button-sliding-icon ripple-effect big margin-top-20 margin-right-5"
              >View Auto Messages <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
            </div>
          </div>

        </div>
      </div>


    </div>
    <!-- Auto Messages Section / End -->

    <!-- Category Boxes -->
    <div class="section margin-top-65 margin-bottom-50" id="automessages-list">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <div class="section-headline centered margin-bottom-15">
              <h3>Auto Messages</h3>
              <p>Set up your automated text messages</p>
            </div>

            <!-- Category Boxes Container -->
            <div class="categories-container">

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/1" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'clipboard-user']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Welcome Message</h3>
                  <p>Sent when a supporter creates a NEW account</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/2" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'usd-square']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>One-Time Donation</h3>
                  <p>Sent to the supporter after a One-Time Donation</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/3" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'wallet']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Round-Up Sign Up</h3>
                  <p>Sent to the supporter after they sign-up to donate Round-Ups</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/4" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'usd-circle']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Round-Up | Monthly</h3>
                  <p>Sent a day before Round-Up Donations are paid each month</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/5" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'calendar']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Monthly Donation</h3>
                  <p>Sent after a donor signs up to give a Monthly Donation</p>
                </div>
              </a>

              <!-- Category Box -->
              <a href="https://admin.harnessapp.com/auto-messages/6" target="_blank" class="category-box">
                <div class="category-box-icon">
                  <i><font-awesome-icon :icon="['far', 'bell-on']" /></i>
                </div>
                <div class="category-box-content">
                  <h3>Monthly | Recurring</h3>
                  <p>Sent each month after a Monthly Donation is paid</p>
                </div>
              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Category Boxes / End -->

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="generateLinkModal"
                          :id="'small-dialog-3'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a class="link-tab" v-on:click.prevent="switchModalTab('.link')" href="#link">Link</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Register -->
          <div class="popup-tab-content" id="link">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Your Donation Link</h3>
              <span>Below is your customized donation link!
              </span>
            </div>

            <!-- Form -->
            <form method="post">
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'link']"/></i>
                <input type="text"
                       class="input-text with-border"
                       ref="shareLink"
                       :value="donationLink"/>
              </div>
            </form>

            <!-- Button -->
            <button class="margin-top-10 button full-width button-sliding-icon ripple-effect"
                    v-on:click.prevent="copyCode"
            >Copy to Clipboard <i><font-awesome-icon :icon="['far', 'paste']" /></i></button>

            <button class="margin-top-10 button full-width button-sliding-icon ripple-effect"
                    v-on:click.prevent="openLink('https://admin.harnessapp.com/messages/new?body=' + donationLink)"
            >Send via Text <i><font-awesome-icon :icon="['far', 'comment-alt-dots']" /></i></button>

            <!-- Social Login -->
            <div class="social-login-separator"><span>or</span></div>
            <div class="social-login-buttons">
              <button class="facebook-login ripple-effect"
                      v-on:click.prevent="openLink('https://www.facebook.com/sharer/sharer.php?u=' + donationLink)"
              ><font-awesome-icon class="margin-right-5" :icon="['fab', 'facebook-f']"/> Share on Facebook
              </button>
              <button class="twitter-login ripple-effect"
                      v-on:click.prevent="openLink('https://twitter.com/home?status=' + donationLink)"
              ><font-awesome-icon class="margin-right-5" :icon="['fab', 'twitter']"/> Share on Twitter
              </button>
            </div>

          </div>

        </div>
      </div>
    </magnific-popup-modal>

  </div>
</template>

<script>
  import $ from 'jquery'
  import projectMixin from '../Parts/project-mixin';
  import donateButtonMixin from './donate-button-mixin';
  import CampaignsSlider from '../Campaigns/campaigns-slider.vue'
  import campaignsMixin from '../Campaigns/campaigns-mixin';
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import Snackbar from 'node-snackbar';
  import {mapActions} from 'vuex'
  import SlickCarousel from '@/components/SlickCarousel/SlickCarousel';

  export default {
    name: 'DonationOptions',
    mixins: [projectMixin, donateButtonMixin, campaignsMixin],
    props: ['isLogin', 'donorDetail', 'charityInfo'],
    components: {
      CampaignsSlider,
      MagnificPopupModal,
      SlickCarousel,
    },
    mounted() {
      this.insertButton();
      // this.initBlogCarousel();
      this.upCampaigns();
    },
    methods: {
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      openLink(link) {
        window.open(link, '_blank');
      },
      copyCode() {
        this.$refs.shareLink.select();
        document.execCommand('copy');

        Snackbar.show({
          text: 'Copied to clipboard!',
          pos: 'top-center',
          showAction: false,
          textColor: '#fff',
          duration: 3000,
          backgroundColor: this.charityInfo.primary_color
        });

      },
      upCampaigns() {
        if (this.charityInfo) {
          this.updateCampaigns({charity_id: this.charityInfo.charity_id});
        }
      },
      insertButton() {
        if (this.charityInfo) {
          this.attachJsExampleV2(this.charityInfo.charity_id);
        }
      },
      switchModalTab(selector) {
        $(selector).click();
      },
      showLinkModal() {
        if (this.selectedType !== this.DonationTypes.ROUNDUP &&
          this.donationAmount === ''
        ) {
          this.$refs.donationAmount.focus();
          return false;
        }

        this.$refs.generateLinkModal.open();

        this.switchModalTab('.link-tab');
      },
    },
    computed: {
      selectedCampaign() {
        if (this.selectedCampaignId && this.allCampaigns.length) {
          return this.allCampaigns.reduce((res, cmp) => cmp.id == this.selectedCampaignId ? cmp : res, null)
        }
        return null;
      },
      orderedCampaigns() {
        return this.sortCampaigns([...this.allCampaigns], 'alphabet');
      },
      donationLink() {

        let queryParams = {
          'selected-method': this.selectedType
        };
        if (this.selectedCampaignId) {
          queryParams['campaign_id'] = this.selectedCampaignId;
        }

        if (this.selectedType !== this.DonationTypes.ROUNDUP) {
          queryParams['amount'] = this.donationAmount;
        }

        return window.location.origin + this.$router.resolve({name: 'Donate', query: queryParams}).href;
      }
    },
    watch: {
      'charityInfo'() {
        this.insertButton();
        this.upCampaigns();
      },
    },
    data() {
      return {
        selectedCampaignId: false,
        selectedType: 'one-time',
        donationAmount: '',
        settings: {
          smart_ask: true,
          display_in_honor: true,
          display_donation_note: true,
          cover_fees: 'yes_checked',//'yes_unchecked', 'no'
        },
        blogCarousel: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          prevArrow: `<button type="button" class="slick-prev">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left">
<path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path>
</svg></button>`,
          nextArrow: `<button type="button" class="slick-next">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right">
<path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
</svg></button>`,
          dots: false,
          arrows: true,
          responsive: [
            {
              breakpoint: 1365,
              settings: {
                slidesToShow: 3,
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                dots: true,
                arrows: false
              }
            }
          ]
        }
      }
    }
  }
</script>
