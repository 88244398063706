<template>

  <div class="intro-banner"
       v-bind:style="styleBanner">
    <div class="container">
      <!-- Intro Headline -->
      <div class="row">
        <div class="col-md-12">
          <div class="banner-headline">
            <h1>
              <strong>Make a Donation</strong>
              <br>
              <h2 style="font-size: 100px; margin-top: 60px; margin-bottom: 50px">Text
                <span class="secondary-color fw-600" style="background-color:#fff; border-radius: 30px;">&nbsp;KEYWORD&nbsp;</span>
              </h2>
              <h2 style="font-size: 100px; margin-top: 100px;">To <span class="fw-600">269-89</span></h2>
            </h1>
          </div>
        </div>
      </div>
      <!-- Stats -->
      <div class="row">
        <div class="col-md-12">
          <ul class="intro-stats margin-top-75 hide-under-992px">
            <li>
              <strong class="counter">0</strong>
              <span>Donations</span>
            </li>
            <li>
              <strong class="counter"><sup>$</sup>0</strong>
              <span>Total Donated</span>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import counterUp from 'counterup2'

  export default {
    name: 'KeywordLanding',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    mounted() {
      [...document.querySelectorAll('.counter')].map(el => {
        counterUp(el, {
          duration: 1000,
          delay: 16,
        })
      });
    },
    computed: {
      styleBanner() {
        let res = {padding: '100px 0'};
        if (this.charityInfo && this.charityInfo.cover_photo) {
          res['background-image'] = `url('${this.charityInfo.cover_photo}')`;
        }
        return res;
      }
    }
  }
</script>
