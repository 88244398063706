<template>
  <div>
    <inner-layout :charity-info="charityInfo">

      <div class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text">
          <h3 style="font-size: 26px;">Share2Care</h3>
          <span>Maximize your impact by spreading the word!</span>
        </div>

        <!-- Cover Image -->
        <div class="welcome-text margin-top-30">
              <span class="company-logo">
                <img :src="require('./images/invite-friends-new.png')"
                     style="max-width:100%; margin-top: -10px; margin-bottom: -10px; border-radius: 4px;"
                     alt="Invite Friends">
              </span>
        </div>

        <div class="welcome-text margin-bottom-15">
          <h4 style="font-size: 18px;"><strong>Sharing is Caring!</strong></h4>
          <span><a href="#"><b><em>Shares</em></b></a> are worth <a href="#"><b>$25</b></a> to our cause
                <br><a href="#"><b><em>Invites</em></b></a> lead to an average donation of <a href="#"><b>$50</b></a>
              </span>
        </div>

        <!-- Button -->
        <a  href="#"
           class="popup-with-zoom-anim"
           v-on:click.prevent="$refs.inviteModal.open()"
        >
          <button class="button full-width button-sliding-icon ripple-effect margin-top-10 popup-with-zoom-anim"
          >Invite Friends <i><font-awesome-icon :icon="['far', 'envelope']" /></i></button>
        </a>

        <!-- Social Login -->
        <div class="social-login-separator"><span>or</span></div>
        <div class="social-login-buttons margin-bottom-10">
          <button class="facebook-login ripple-effect"
                  v-on:click.prevent="openLink('https://www.facebook.com/sharer/sharer.php?u=' + pageLink)"
          ><font-awesome-icon class="margin-right-5" :icon="['fab', 'facebook-f']"/> Share on Facebook
          </button>
          <button class="twitter-login ripple-effect"
                  v-on:click.prevent="openLink('https://twitter.com/home?status=Join me in supporting ' + (charityInfo ? charityInfo.charity_name : '') + '! Use this link to give: ' + pageLink)"
          ><font-awesome-icon class="margin-right-5" :icon="['fab', 'twitter']"/> Share on Twitter
          </button>
        </div>
      </div>
    </inner-layout>

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="inviteModal"
                          :id="'sign-in-dialog'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#email-tab">Email</a></li>
          <li v-if="md && md.mobile()"><a href="#text-tab">Text</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Login -->
          <div class="popup-tab-content" id="email-tab">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Invite by Email</h3>
              <span>Email your friends an invitation</span>
            </div>

            <!-- Form -->
            <form method="post" id="email-form">
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
                <input type="email"
                       v-model="emailAddress"
                       class="input-text with-border"
                       placeholder="Email Address"
                       required/>
              </div>

              <textarea cols="10"
                        placeholder="Write a Message..."
                        class="with-border"
                        v-model="emailBody"
                        required></textarea>
            </form>

            <a v-if="emailBody && emailAddress"
               :href="mailToLink"
               class="button ripple-effect button-sliding-icon margin-top-5"
            >Send Email Invites <i><font-awesome-icon :icon="['far', 'envelope']"/></i></a>

            <button v-else class="button ripple-effect button-sliding-icon margin-top-5"
                    type="submit"
                    form="email-form">
              Send Email Invites <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>

          </div>

          <!-- Register -->
          <div v-if="md && md.mobile()" class="popup-tab-content" id="text-tab">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Invite by Text</h3>
              <span>Send invites to your friends via Text</span>
            </div>

            <!-- Form -->
            <form method="post" id="text-form">
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'phone-alt']"/></i>
                <input type="tel"
                       v-model="phoneNumber"
                       class="input-text with-border"
                       placeholder="Phone Number"
                       required/>
              </div>

              <textarea name="textarea"
                        cols="10"
                        placeholder="Write a Message..."
                        class="with-border"
                        v-model="smsText"
                        required></textarea>
            </form>

            <a v-if="phoneNumber && smsText"
               :href="smsToLink"
               class="button ripple-effect button-sliding-icon margin-top-5"
            >Send Text Invites <i><font-awesome-icon :icon="['far', 'check']"/></i></a>

            <button v-else
                    class="button full-width button-sliding-icon ripple-effect"
                    type="submit"
                    form="text-form"
            >Send Text Invites <i><font-awesome-icon :icon="['far', 'check']"/></i></button>

          </div>

        </div>
      </div>
    </magnific-popup-modal>

  </div>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import MagnificPopupModal from '../../components/Parts/MagnificPopupModal'

  const MobileDetect = require('mobile-detect');

  export default {
    name: 'InviteFriends',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    components: {
      InnerLayout,
      MagnificPopupModal
    },
    mounted() {
      this.md = new MobileDetect(window.navigator.userAgent);

      this.setDefaultText();
    },
    methods: {
      setDefaultText(){
        if (this.charityInfo) {
          this.emailBody = `I thought you might be interested in supporting ${this.charityInfo.charity_name}! Check out some of the campaigns they have going on! ${this.campaignsLink}`;
          this.smsText = `I thought you might be interested in supporting ${this.charityInfo.charity_name}! Check out some of the campaigns they have going on! ${this.campaignsLink}`;
        }
      },
      openLink(link) {
        window.open(link, '_blank');
      }
    },
    computed: {
      pageLink() {
        return window.location.origin + this.$router.resolve('/').href;
      },
      campaignsLink() {
        return window.location.origin + this.$router.resolve({name:'CampaignsList'}).href;
      },
      mailToLink(){
        let subject = 'Invitation to support';
        if (this.charityInfo && this.donorDetail) {
          subject = `${this.donorDetail.first_name} has invited you to support ${this.charityInfo.charity_name}`;
        }
        return `mailto:${this.emailAddress}?subject=${subject}&body=${this.emailBody}`;
      },
      smsToLink(){
        if(this.md.is('AndroidOS')){
          return `sms:${this.phoneNumber}?body=${this.smsText}`;
        }
        return `sms:${this.phoneNumber}&body=${this.smsText}`;
      }
    },
    watch: {
      'charityInfo'() {
        this.setDefaultText();
      },
    },
    data() {
      return {
        md: null,
        emailAddress: '',
        emailBody: '',

        phoneNumber: '',
        smsText: '',
      }
    }
  }
</script>